import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Header from "../refresh/Header";
import { LanguageContext } from "../../quotes/shared/HomeContext";
import { ThemeContext } from "../refresh/ThemeContext";
import { texts } from "../../text";
import "./Leaving.css";
// Import RefreshStyles to ensure consistent styling
import "../refresh/RefreshStyles.css";
// Import the logo directly
import partnerLogo from './youset-logo.png';
import partnerLogoColor from './youset-logo-color.png'; // Light version (for dark backgrounds)


// Text content in both languages
const leavingTexts = {
  en: {
    redirecting: "We are now transferring you to our partner site to complete your online quote.",
    notRedirected: "If you are not redirected within 30 seconds, please",
    continue: "click to continue."
  },
  fr: {
    redirecting: "Nous vous transférons maintenant vers le site de notre partenaire pour compléter votre soumission en ligne.",
    notRedirected: "Si vous n'êtes pas redirigé dans les 30 secondes, veuillez",
    continue: "cliquer pour continuer."
  }
};

function Leaving() {
  const { language, setLanguage } = useContext(LanguageContext);
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const [progress, setProgress] = useState(0);
  
  // Check URL for language
  useEffect(() => {
    // If URL contains '/fr', set language to French
    if (location.pathname.includes('/fr')) {
      setLanguage('fr');
      localStorage.setItem('language', 'fr');
    }
  }, [location.pathname, setLanguage]);
  
  // Apply stored theme when component mounts
  useEffect(() => {
    const storedDarkMode = localStorage.getItem('isDarkMode');
    console.log("Retrieved dark mode state:", storedDarkMode);
    console.log("Current theme context:", themeContext);
    
    if (themeContext && themeContext.toggleDarkMode) {
      const shouldBeDark = storedDarkMode === 'true';
      const isDark = themeContext.darkMode === true;
      
      console.log("Should be dark:", shouldBeDark);
      console.log("Is currently dark:", isDark);
      
      // Only toggle if there's a mismatch
      if (shouldBeDark !== isDark) {
        console.log("Toggling dark mode");
        themeContext.toggleDarkMode();
      }
    } else {
      console.log("Theme context or toggle function not available");
      
      // Fallback: apply CSS variables directly if needed
      if (storedDarkMode === 'true') {
        console.log("Applying dark mode CSS variables directly");
        document.documentElement.setAttribute('style',
        `--gray: #0D172E;
        --royal-blue-sidebar: #0D172E;
        --button-fill: #B6B6CE;
        --language-fill: #FFFFFF;
        --background-gray: #1D2B4B;
        --body-text: #FFFFFF;
        --reverse-body-text: #000000;
        --progress-bar: #637191;
        --progress: white;
        --button-color: #0D172E;
        --phone-number: #637191;
        --button-background: #FFFFFF;
        --button-text: #0D172E;
        --modal-background: #1D2B4B;
        --modal-close-button: #0D172E;
        --discount-path: #1D2B4B;
        --modal-close-button-hover: #0047B3;
        --disabled: #0D172E;
        --copyright: #00000080;
        --dim-black-2: #FFFFFF;
        --magenta: #FFFFFF;
        --language-active: #8CC1FF;`);
      } else if (storedDarkMode === 'false') {
        console.log("Applying light mode CSS variables (removing dark mode)");
        document.documentElement.setAttribute('style', '');
      }
    }
  }, [themeContext]);
  
  // Ensure language is typed correctly for accessing leavingTexts
  const lang = (language === 'fr' ? 'fr' : 'en') as keyof typeof leavingTexts;
  // Very simple version (for you to implement however you wish):
  
  // Set page title based on language
  useEffect(() => {
    if (language === "fr") {
      document.title = "Assurance KBD - Redirection";
    } else {
      document.title = "KBD Insurance - Redirecting";
    }
  }, [language]);
  
  // Get destination URL from various sources
  const getDestinationUrl = () => {
    // Try to get from session storage
    const storedUrl = sessionStorage.getItem('destinationUrl');
    if (storedUrl) {
      return storedUrl;
    }
    
    // Default URLs based on language and previous page
    const isCarRoute = sessionStorage.getItem('insuranceType') === 'car' ||
                      location.pathname.includes('/car');
    
    if (isCarRoute) {
      return language === 'fr'
        ? 'https://www.app.youset.ca/source?sourceId=sou_01956d7c8d52771597e0c22371ae193c&lng=fr&p=car&utm_source=kbd&utm_medium=partners'
        : 'https://www.app.youset.ca/source?sourceId=sou_01956d7c8d52771597e0c22371ae193c&lng=en&p=car&utm_source=kbd&utm_medium=partners';
    } else {
      return language === 'fr'
        ? 'https://www.app.youset.ca/source?sourceId=sou_01956d7c8d52771597e0c22371ae193c&lng=fr&p=home&utm_source=kbd&utm_medium=partners'
        : 'https://www.app.youset.ca/source?sourceId=sou_01956d7c8d52771597e0c22371ae193c&lng=en&p=home&utm_source=kbd&utm_medium=partners';
    }
  };
  
  // Progress bar animation and redirect
  useEffect(() => {
    // Ensure progress starts at 0
    setProgress(0);
    
    const destinationUrl = getDestinationUrl();
    const duration = 3000; // 3 seconds in milliseconds
    const startTime = Date.now();
    
    // Use requestAnimationFrame for smoother animation
    const animateProgress = () => {
      const elapsed = Date.now() - startTime;
      const progressPercent = Math.min(100, (elapsed / duration) * 100);
      
      setProgress(progressPercent);
      
      if (elapsed < duration) {
        // Continue animation
        requestAnimationFrame(animateProgress);
      } else {
        // Redirect when animation completes
        window.location.href = destinationUrl;
      }
    };
    
    // Start animation
    const animationId = requestAnimationFrame(animateProgress);
    
    // Redirect as a fallback
    const redirectTimer = setTimeout(() => {
      window.location.href = destinationUrl;
    }, duration + 500); // Adding extra time as a failsafe
    
    return () => {
      // Clean up
      cancelAnimationFrame(animationId);
      clearTimeout(redirectTimer);
    };
  }, [language]);

  // Get the destination URL for the click to continue link
  const destinationUrl = getDestinationUrl();

  return (
    <div className="leaving-page t-main">
      <Header />
      <div className="t-container">
        <div className="t-content-container">
          <div className="t-text-box">
            <h1 className="question-heading">{leavingTexts[lang].redirecting}</h1>
          </div>
          
          <div className="progress-wrapper">
            <img src={partnerLogo} className="dark-mode-logo" alt="Partner Logo" />
            <img src={partnerLogoColor} className="light-mode-logo" alt="Partner Logo" />
          
            <div className="progress-bar">
              <div style={{
                height: '100%', 
                width: `${progress}%`, 
                borderRadius: '0.4rem', 
                background: 'var(--progress)', 
                transition: 'width 0.4s'
              }}></div>
            </div>
          </div>
          
          <div className="leaving-footer copyright">
            <p>
              {leavingTexts[lang].notRedirected}{' '}
              <a href={destinationUrl}>
                {leavingTexts[lang].continue}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leaving;