import ArrowRight from "./assets/ArrowRight";
import { LanguageContext } from "../../quotes/shared/HomeContext";
import { CarLanguageContext } from "../../quotes/car/CarContext";
import { ThemeContext } from "../refresh/ThemeContext";
import { texts, carTexts } from "../../text";
import { useContext, useEffect, useState } from "react";
import Question from "../refresh/Question";
import { useLocation } from "react-router-dom";

interface OntarioButtonProps {
  insuranceType?: 'car' | 'home';
}

const OntarioButton = ({ insuranceType = 'car' }: OntarioButtonProps) => {
    const { language } = useContext(LanguageContext);
    const { carLanguage } = useContext(CarLanguageContext);
    const themeContext = useContext(ThemeContext);
    const [mobile, setMobile] = useState<boolean>(false);
    const location = useLocation();
    
    useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 576px)");
    
      const handleMediaQueryChange = (e: MediaQueryListEvent) => {
        setMobile(e.matches);
      };
    
      // Set initial value
      setMobile(mediaQuery.matches);
    
      // Add listener for changes
      mediaQuery.addEventListener("change", handleMediaQueryChange);
    
      // Cleanup
      return () => {
        mediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }, []);

    // Handle button click with navigation to appropriate language route
    const handleClick = () => {
      // Get insurance type and language info
      const path = location.pathname;
      const isCarRoute = path.includes('/car');
      const isFrenchRoute = path.endsWith('/fr');
      
      // Store in session storage for the leaving page
      sessionStorage.setItem('insuranceType', isCarRoute ? 'car' : 'home');
      
      // Store current theme state - use ThemeContext if available
      if (themeContext && themeContext.darkMode !== undefined) {
        localStorage.setItem('isDarkMode', themeContext.darkMode ? 'true' : 'false');
        console.log("Stored dark mode state:", themeContext.darkMode);
      } else {
        // Fallback: check CSS variables directly
        const isDarkMode = document.documentElement.getAttribute('style')?.includes('--background-gray: #1D2B4B');
        localStorage.setItem('isDarkMode', isDarkMode ? 'true' : 'false');
        console.log("Stored dark mode state (from CSS):", isDarkMode);
      }
      
      // Navigate to the leaving page with appropriate language
      if (isFrenchRoute) {
        window.location.href = '/leaving/fr';
      } else {
        window.location.href = '/leaving';
      }
    };

    return (
        <div className="ontario-button">
            {mobile ? (
            <>
              {location.pathname.includes('/car') ? (
                <Question
                  subQuestion={carTexts[carLanguage].forOntario}
                  className={"ontario"}
                />
              ) : (
                <Question
                  subQuestion={texts[language].forOntario}
                  className={"ontario"}
                />
              )}
              <button
                className="btn pink text-with-arrow-left footer-btns"
                onClick={handleClick}
                type="button"
              >
              <div>
                <ArrowRight />
              </div>
              </button>
            </>
            ) : (
            <>
              <button
                className="btn pink text-with-arrow-left footer-btns"
                onClick={handleClick}
                type="button"
              >
              <div>
                <ArrowRight />
              </div>
              </button>  
              {location.pathname.includes('/car') ? (
                <Question
                  subQuestion={carTexts[carLanguage].forOntario}
                  className={"ontario"}
                />
              ) : (
                <Question
                  subQuestion={texts[language].forOntario}
                  className={"ontario"}
                />
              )}
            </>
          )}
        </div>
      );
    }

export default OntarioButton